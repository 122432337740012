/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/policy"
  }, "Policy")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/consent"
  }, "Consent")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/trackerdetect"
  }, "Trackerdetect")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/channels"
  }, "Channels")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/webhooks"
  }, "Webhooks")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/events"
  }, "Events")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
